button {
  cursor: pointer;
}

.scadmin_logout_button {
    border: 2px solid gray;
    border-radius: 3rem;
    color: #3B465A;
    font-family: roboto;
    font-size: 1.5rem;
    font-weight: 100;
    overflow: hidden;
    padding: 1rem 2rem;
    position: relative;
    text-decoration: none;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    margin-right: 1em;
  }

  .scadmin_logout_button::after {
    background-color: #4CAC9F;
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }

  .scadmin_logout_button:hover::after {
    transform: translate(0, 0);
  }

  .scadmin_logout_button:hover {
    border: 2px solid transparent;
    color: white;
    transform: scale(1.05);
    will-change: transform;
  }

  .loginLeft {
    width: 45rem;
    height: 100vh;
    background: linear-gradient(180deg, #4790B4 0%, #53BCA5 100%);
  }

  .loginLeft__logo {
    position: fixed;
    bottom: 5rem;
    left: -14rem;
    width: 73rem;
    z-index: 0;
  }

  @media (max-width: 768px) {
    .loginLeft {
      display: none;
    }
  }

  .loginRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: white;
    flex: 1;
    z-index: 1;
    overflow: auto;
    height: 100vh;
    padding-bottom: 10rem;
  }

  .loginRight__container {
    width: 35rem;
    margin-left: 5rem;
  }

  @media (max-width: 768px) {
    .loginRight__container {
      margin: 0;
    }
  }

  .loginRight__logo {
    width: 26rem;
  }

  .loginRight__logo-wrapper {
    margin-bottom: 5rem;
    margin-top: 5rem;
  }

  .loginRight__finePrint {
    margin-top: 6.5rem;
    color: darkgray;
    font-size: 1.4rem;
    opacity: 0.8;
    cursor: pointer;
  }

  .loginRight__finePrint div {
    padding-bottom: 1rem;
  }


.loginContainer {
    width: 100vw;
    display: flex;
  }

  .loginForm input {
    border: none;
    border-bottom: 1.5px solid #acbacf;
    border-radius: 0;
  }

  .loginForm {
    font-size: large;
  }

  .loginForm label {
    color: #869bb8;
  }

  .loginForm .inputField {
    padding-bottom: 5rem;
  }

  .loginButtonContainer {
    display: flex;
  }

  @media (max-width: 768px) {
    .loginButtonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  #passwordField,
  #passwordFieldConfirm {
    letter-spacing: 0.35em;
  }

  /* .formError */
  .formError {
    font-size: small;
    color: rgba(red, 0.6);
    width: 17.5rem;
    letter-spacing: 0.05rem;
    position: absolute;
    margin-left: 1rem;
  }


  .mainBtn {
    width: 18rem;
    height: 5.5rem;
    background: linear-gradient(109.54deg, #54BCA8 6.55%, #4CA2AE 94.46%);
    border: 1px solid rgba(80,103,136,.35);
    box-sizing: border-box;
    box-shadow: 2px 3px 2px rgba(0,0,0,.1);
    border-radius: 5px;
    color: #fff;
    font-size: 2rem;
    margin: 1rem;
  }

  .error{
    color: red;
    padding-left: 2em;
    padding-bottom: 2em;
  }


  .inputField__input {
    resize: none;
    font-family: "Heebo",Arial,sans-serif;
    color: #506788;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
    font-size: 1.6rem;
    filter: none;
    background: none;
    padding-left: 1rem;

    transition: background-color .75s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
