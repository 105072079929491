.sca-layout .header {
  display: flex;
  justify-content: space-between;
}
.sca-layout .header div {
  display: flex;
  align-items: center;
}
.sca-layout .page {
  left: 24rem;
}
.sca-layout .sidebar-icon {
  position: fixed;
  bottom: 3rem;
  left: -7rem;
  z-index: -1;
  width: 28rem;
  opacity: 0.9;
}
.sca-layout .sidebar {
  display: flex;
  flex-direction: column;
  width: 24rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  background-color: #506788;
  position: absolute;
  top: 5.5rem;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: -2;
}
.sca-layout .sidebar .link {
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  color: #E9EEF4;
  border-radius: 1rem 0 0 1rem;
}
.sca-layout .sidebar .link div {
  display: flex;
  align-items: center;
}
.sca-layout .sidebar .link div .icon {
  margin-right: 2rem;
}
.sca-layout .sidebar .link:hover {
  background-color: #344358;
}
.sca-layout .sidebar .link .active {
  background-color: #344358;
}
.sca-layout .form .section .sub-heading {
  border-bottom: 1.5px solid #acbacf;
}
@media screen and (max-width: 1250px) {
  .sca-layout .form .section {
    width: 100%;
  }
  .sca-layout .form .section .row {
    flex-direction: column;
  }
}
.sca-layout .form .section .group .row.list, .sca-layout .form .section .group .row.list-email {
  flex-direction: column;
  justify-content: unset;
  padding-left: 1rem;
  padding-right: 1rem;
}
.sca-layout .form .section .group .row.list .item, .sca-layout .form .section .group .row.list-email .item {
  text-decoration: none;
  width: 100%;
}
.sca-layout .form .section .group .row.list-email {
  padding-left: 0;
  padding-right: 0;
  align-items: flex-start;
}
.sca-layout .form .section .group .row.list-email .item {
  width: 38rem;
}
@media screen and (max-width: 1250px) {
  .sca-layout .form .section .group .row.list-email .item {
    width: fit-content;
  }
}
.sca-layout .form .section .group .row input[disabled] {
  opacity: 70%;
}
.sca-layout .form .section .group .row .field-copy {
  margin-top: 3rem;
}
@media screen and (max-width: 1250px) {
  .sca-layout .form .section .group .row .field-copy {
    margin-top: 0;
  }
}
@media screen and (max-width: 1250px) {
  .sca-layout .form {
    width: 100%;
    min-width: 320px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

