// Colors

$black: #232F42;
$m-dark-grey: #ACBACF;
$m-grey: #E9EEF4;
$light-grey: #F5F7F9;
$white: #FFFFFF;
$blue: #007AFF;
$dark-blue: #506788;
$m-dark-blue: #7689A3;
$green: #00D89D;
$light-green: #34D6AA;
$red: #FF005C;
$yellow: #FDB600;

// Spacing

$xl-size: 4.8rem;
$l-size: 3.2rem;
$m-size: 1.6rem;
$s-size: 1.2rem;

// Font Size

$font-size-large: 1.8rem;
$font-size-medium: 1.6rem;
$font-size-small: 1.4rem;


// Breakpoints

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;


@mixin flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-column-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin flex-horizontal-center-vertical {
	display: flex;
	align-items: center;
}