@import "./utils.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

* {
    box-sizing: border-box;
}

*::-webkit-scrollbar {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    height: 56px;

    border-radius: 8px;
    border: 4px solid transparent;

    background-clip: content-box;
    background-color: rgba($m-dark-grey, 0.75);
}

html {
    font-size: 62.5%;
}

body {
    overflow: hidden;
    margin: 0;

    font-family: "Heebo", Arial, sans-serif;
    font-size: $m-size;
    letter-spacing: 0.05em;
    line-height: 1.6;
    color: $black;

    background-color: $light-grey;
}

.button {
    width: 15rem;
    padding: 1rem;
    margin: 1rem;

    background: $m-grey;
    border: 1px solid rgba(80, 103, 136, 0.5);
    box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;

    color: $dark-blue;
    font-size: 1.4rem;

    &:hover:enabled {
        background-color: $light-grey;
    }

    &.green {
        background: $green;

        font-weight: bold;
        color: $white;

        &:hover:enabled {
            background-color: #00e7a9;
        }
    }

    &.blue {
        background: #3b465a;

        color: $white;

        &:hover:enabled {
            background: #3b465a;
            scale: 110%;
        }
    }

    &.white {
        color: #3b465a;
        background-color: $white;

        border: 1.5px solid #3b465a;

        &:hover:enabled {
            background-color: $white;
            scale: 110%;
        }
    }

    &.red {
        background: $red;

        font-weight: bold;
        color: $white;

        &:hover:enabled {
            background-color: #ff3680;
        }
    }

    &.warning {
        background: repeating-linear-gradient(45deg, $black, $black 10px, $yellow 5px, $yellow 20px);

        color: $white;

        border: 1px solid $black;

        span {
            background-color: $red;
            padding: 0.2rem 1rem 0.2rem 1rem;
            border: 1px solid $white;
            border-radius: 4px;
        }
    }

    &-file {
        @extend .button;

        height: 4rem;

        text-align: center;
        line-height: 2rem;
    }

    &[disabled] {
        opacity: 50%;

        cursor: default;
    }
}

.toggle {
    display: flex;
    justify-content: space-evenly;
    height: 5.2rem;

    .option {
        width: 100%;

        background-color: $white;
        border: none;
        border: 1.5px solid #acbacf;
        border-radius: 5px;

        font-size: $font-size-small;
        color: lighten($m-dark-blue, 10%);

        &.left {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.right {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.selected {
            border: 1.5px solid #0099ff;
            background-color: #0096d61c;

            color: #0099ff;

            &.left {
                border-right: 1.5px solid #0099ff;
            }

            &.right {
                border-left: 1.5px solid #0099ff;
            }
        }

        &[disabled] {
            opacity: 50%;

            cursor: not-allowed;
        }
    }
}

.tooltip {
    .text {
        position: absolute;
        z-index: 99;

        min-width: 10rem;
        max-width: 40rem;

        padding: 1rem;

        background-color: #3b465af1;
        border-radius: 5px;

        color: $white;
        font-weight: normal;

        @media screen and (max-width: 1000px) {
            max-width: 20rem;
        }
    }

    .right {
        transform: translateX(+1.5rem);
    }

    .left {
        transform: translateX(calc(-100% + -0.5rem));
    }

    .icon {
        font-size: 1rem;
        opacity: 80%;
    }
}

.page {
    @include flex-column-centered();
    position: absolute;
    top: 5.5rem;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow-y: auto;
    overflow-x: hidden;
}

.background-icon {
    position: fixed;
    bottom: -10rem;
    right: -10rem;
    z-index: -1;
    width: 55rem;

    opacity: 0.04;
}

.header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 200;
    height: 5.5rem;
    width: 100vw;

    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

    h1 {
        font-size: $font-size-small;
        font-weight: 100;
        color: $dark-blue;
    }

    h2 {
        margin-right: 2rem;
        margin-left: 2rem;

        font-size: $font-size-small;
        font-weight: 100;
        color: $dark-blue;

        @media screen and (max-width: 400px) {
            display: none;
        }
    }

    div {
        @include flex-horizontal-center-vertical();
    }

    &-org-home {
        @extend .header;
        background-color: #3b465a;

        h1,
        h2 {
            color: $white;
        }
    }
}

.logo {
    width: 5rem;
    margin-right: 2rem;
    margin-left: 2rem;
}

.form {
    @include flex-column-centered();
    margin-top: 4rem;
    margin-bottom: 4rem;

    font-size: $font-size-small;
    color: lighten($m-dark-blue, 10%);

    .buttons {
        display: flex;
        justify-content: flex-end;
        padding-right: 4rem;
        padding-left: 4rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media screen and (max-width: 650px) {
            flex-direction: column;
            align-items: center;

            .button {
                width: 100%;
            }
        }
    }

    .section {
        width: 90rem;
        margin: 2rem;

        background-color: $white;
        border-radius: 5px;
        border: 1.5px solid #acbacf;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);

        .label {
            display: flex;
            gap: 0.5rem;
        }

        .sub-heading {
            @include flex-centered();
            height: 4rem;

            background: #e2e9f1;
            border-bottom: 4px solid $green;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;

            font-weight: bold;
            color: $m-dark-blue;

            &-summary {
                @extend .sub-heading;
                border-bottom: 1.5px solid #acbacf;
            }
        }

        .group {
            @include flex-column();
            padding: 4rem;

            .first-p {
                margin-top: 0;
            }

            .last-p {
                margin-bottom: 0;
            }

            p {
                .red {
                    font-weight: bold;
                    color: $red;
                }
            }

            .row {
                @include flex-space-between();

                .field {
                    @include flex-column();
                    width: 100%;
                    padding: 1rem;
                    overflow-x: auto;

                    &-button {
                        @extend .field;
                        .button {
                            margin: 0;
                            margin-top: 2rem;
                        }
                    }

                    &-checkbox {
                        @extend .field;

                        align-items: flex-start;

                        input {
                            width: 4rem;
                            height: 4rem;
                        }
                    }
                }

                input {
                    height: 5.2rem;

                    padding-left: 1rem;
                    padding-right: 1rem;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;

                    border: 1.5px solid #acbacf;
                    border-radius: 5px;
                    background-color: $white;

                    color: $dark-blue;
                    font-size: 1.6rem;

                    &[disabled] {
                        background-color: $light-grey;
                    }

                    &[type="date"] {
                        padding-right: 2rem;

                        font-family: inherit;
                    }

                    &[type="radio"] {
                        height: 1rem;
                        margin-right: 1rem;
                    }

                    &[type="file"] {
                        display: none;
                    }

                    &[type="search"] {
                        width: 100%;
                    }
                }

                textarea {
                    @extend input;

                    height: 11rem;

                    resize: none;

                    font-family: "Heebo", Arial, sans-serif;
                    color: $dark-blue;
                    font-size: 1.6rem;

                    &[disabled] {
                        opacity: 70%;
                    }
                }

                select {
                    @extend input;

                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }

                .file-input {
                    @extend input;

                    display: flex;
                    flex-direction: column;

                    height: fit-content;

                    .row {
                        justify-content: flex-start;
                    }
                }

                .error-highlight {
                    color: $red;

                    input {
                        border-color: $red;
                    }
                }

                .errors {
                    padding: 1rem 1rem 1rem 3rem;

                    color: $red;
                    border: 1.5px solid $red;
                    border-radius: 5px;
                }

                .search-results {
                    max-height: 17rem;

                    list-style-type: none;

                    padding: 0;
                    margin: 0;
                    margin-left: 1rem;
                    margin-right: 1rem;

                    overflow-x: auto;

                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    border: 1.5px solid #acbacf;
                    border-top: none;

                    background-color: $white;

                    li {
                        padding: 1rem 1rem 1rem 1rem;

                        white-space: nowrap;

                        cursor: pointer;

                        background: $white;

                        &:hover {
                            background-color: $m-grey;
                        }
                    }

                    li:nth-child(even) {
                        background: $light-grey;

                        &:hover {
                            background-color: $m-grey;
                        }
                    }
                }
            }

            .divider {
                margin-top: 2rem;
                margin-bottom: 2rem;
                margin-left: 1rem;
                margin-right: 1rem;

                &-hard {
                    @extend .divider;
                    border-bottom: 1.5px solid #acbacf;
                }
            }

            .annotation {
                font-size: 1rem;
            }
        }

        @media screen and (max-width: 950px) {
            width: 100%;

            .row {
                flex-direction: column;
            }
        }

        @media screen and (max-width: 1250px) {
            .row {
                .field {
                    &.spacer {
                        display: none;
                    }
                }
            }
        }
    }

    .side-by-side-tables {
        width: 90rem;
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        .table-container {
            width: 100%;
            height: fit-content;
        }

        @media screen and (max-width: 1250px) {
            width: 100%;
            flex-direction: column;

            .input-row {
                max-width: none;
            }
        }
    }

    @media screen and (max-width: 950px) {
        width: 100%;
        min-width: 320px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.table-container {
    overflow-x: auto;

    border-radius: 5px;
    border: 1.5px solid #acbacf;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);

    background-color: $white;

    &-full {
        @extend .table-container;

        width: 90rem;
        margin: 2rem;

        @media screen and (max-width: 1250px) {
            width: 100%;
        }
    }

    &-row {
        @extend .table-container;
        margin: 1rem 1rem;
        height: 30rem;
    }
}

table {
    width: 100%;

    thead {
        position: sticky;
        top: 0px;

        background-color: #e2e9f1;

        tr {
            th {
                color: $m-dark-blue;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;

                border-bottom: 1.5px solid #acbacf;

                padding: 1rem 2rem 1rem 2rem;

                .label {
                    display: flex;
                    gap: 0.5rem;
                }
            }
        }

        .tooltip {
            .text {
                white-space: normal;

                @media screen and (max-width: 1200px) {
                    max-width: 20rem;
                }
            }
        }
    }

    tr {
        &.clickable {
            cursor: pointer;

            &:hover {
                background-color: $m-grey;
            }
        }
    }

    td {
        padding: 1rem 2rem 1rem 2rem;

        white-space: nowrap;

        &.clickable {
            cursor: pointer;

            &:hover {
                background-color: $m-grey;
            }
        }

        &.selected {
            border-left: 4px solid $light-green;
        }

        .input-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            max-width: 40rem;

            input {
                padding: 0.6rem 1rem 0.6rem;

                border: 1.5px solid #acbacf;
                border-radius: 5px;
                background-color: $white;

                color: $dark-blue;
                font-size: 1.4rem;

                flex-grow: 1;

                &[disabled] {
                    background-color: $light-grey;
                }
            }

            .button {
                width: 6rem;
            }
        }
    }

    tr:nth-child(even) {
        background: $light-grey;
    }

    .link {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 3rem;

        background: $m-grey;
        border: 1px solid rgba(80, 103, 136, 0.5);
        // box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: $light-grey;
        }

        a {
            width: 100%;

            color: $dark-blue;
            text-align: center;
            text-decoration: none;
        }
    }

    .button {
        height: 3rem;
        width: 12rem;
        margin: 0;

        line-height: 0.5rem;
    }

    // We can remove this class once the certs refactor is complete
    // and we replace the SCA certs page.
    .toggle-col {
        width: 8rem;

        text-align: center;
    }
}

.org-home {
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    overflow-y: auto;

    color: #3b465a;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;

    h2 {
        font-size: 4rem;
        margin-top: 1rem;
        margin-bottom: 0rem;

        @media screen and (max-width: 650px) {
            font-size: 2rem;
        }
    }

    .section {
        display: flex;
        flex-direction: column;

        padding-top: 2rem;
        padding-bottom: 2rem;

        &-white {
            @extend .section;
            background-color: $white;
        }

        &-grey {
            @extend .section;
            background-color: $light-grey;
        }

        &-blue {
            @extend .section;
            background-color: #3b465a;
        }
    }

    .group {
        display: flex;
        justify-content: space-between;
        gap: 4rem;

        margin-left: 20rem;
        margin-right: 20rem;

        margin-top: 2rem;
        margin-bottom: 2rem;

        @media screen and (max-width: 1300px) {
            margin-left: 4rem;
            margin-right: 4rem;
        }

        @media screen and (max-width: 950px) {
            flex-direction: column;
        }

        @media screen and (max-width: 650px) {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        &-welcome {
            @extend .group;

            flex-direction: column;
            gap: 0rem;

            align-items: center;

            text-align: center;

            p {
                width: 60%;
                font-size: 2rem;
                margin-bottom: 0rem;

                @media screen and (max-width: 650px) {
                    font-size: 1.5rem;
                    width: 90%;
                }
            }
        }

        &-faq {
            @extend .group;

            .module {
                border: none;

                .icon {
                    padding: 0rem;
                    font-size: 4rem;
                    color: #3b465a;
                }
            }
        }

        &-buttons {
            @extend .group;
            align-items: center;
            text-align: center;

            h3 {
                color: $white;
                font-size: 4rem;
            }
        }

        .button {
            margin: 0;
            height: 4rem;

            font-family: "Poppins", sans-serif;
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
    }

    .module {
        border: 3px solid #3b465a;
        border-radius: 4px;

        width: 100%;

        padding: 4rem;

        .icon {
            font-size: 2.5rem;
            color: $white;

            border-radius: 2.5px;

            padding: 1.2rem;
        }

        h3 {
            font-size: 2.5rem;
            font-weight: bold;
        }

        &-green {
            @extend .module;

            border-color: #55ab9d;
            background-color: #55ab9d23;

            .icon {
                background-color: #55ab9d;
            }
        }

        &-blue {
            @extend .module;

            border-color: #5591ab;
            background-color: #5591ab23;

            .icon {
                background-color: #5591ab;
            }
        }

        &-purple {
            @extend .module;

            border-color: #9755ab;
            background-color: #9755ab23;

            .icon {
                background-color: #9755ab;
            }
        }

        &-black {
            @extend .module;

            border-color: #3b465a;
            background-color: #3b465a17;

            .icon {
                background-color: #3b465a;
            }
        }
    }
}

@media print {
    body {
        overflow: visible;
        background: none;

        .header {
            background: none;
            box-shadow: none;
            h1 {
                color: black;
            }

            .logo {
                margin-left: 0;
            }
        }

        .page {
            overflow: visible;

            .background-icon {
                display: none;
            }

            .form {
                margin: 0;
                color: black;
                width: 100%;

                .section {
                    width: 100%;
                    margin: 0;
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                    border: 1px solid black;
                    margin: 1rem 1rem;
                    break-inside: avoid;

                    .tooltip {
                        display: none;
                    }

                    .sub-heading {
                        background: none;
                        border: none;
                        justify-content: flex-start;
                        color: black;
                        border: 1px solid black;
                        padding-left: 0.5rem;
                        font-size: 1.2rem;
                    }

                    .group {
                        padding: none;
                        padding: 0;

                        .divider {
                            display: none;
                        }

                        .row {
                            .field {
                                border: 1px solid black;
                                font-weight: bold;
                                padding: 0.5rem;
                                font-size: 0.8rem;
                            }

                            input {
                                height: 1.5rem;
                                padding: 0;
                                color: black;
                                border: none;
                                background: none;
                                border-radius: 0;
                                font-size: 1.2rem;
                            }

                            p {
                                padding: 1rem;
                                border: 1px solid black;
                                margin: 0;
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .print-hidden,
    .button {
        display: none;
    }
}
