@import "./utils.scss";

// Box-sizing, scroll-bars, html, & body styles handled by base.scss

// Some styles in sca-layout build off of styles from base.scss
// Styles inside sca-layout will have higher specificity and
// will override styles from base.scss for sca-layout nested components.

// All styles for sca should be nested within sca-layout in order to
// avoid conflicts with original styling.
.sca-layout {

    .header {
        display: flex;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;
        }
    }

    .page {
        left: 24rem;
    }

    .sidebar-icon {
        position: fixed;
        bottom: 3rem;
        left: -7rem;
        z-index: -1;
        width: 28rem;
        opacity: 0.9;
    }

    .sidebar {
        display: flex;
        flex-direction: column;

        width: 24rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;

        background-color: $dark-blue;

        position: absolute;
        top: 5.5rem;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: -2;

        .link {
            padding-right: 1rem;
            padding-left: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;

            margin-top: 0.5rem;
            margin-bottom: 0.5rem;

            text-decoration: none;
            color: $m-grey;

            border-radius: 1rem 0 0 1rem;

            div {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 2rem;
                }
            }

            &:hover {
                background-color: #344358;
            }

            .active {
                background-color: #344358;
            }
        }
    }

    .form {
        .section {
            .sub-heading {
                border-bottom: 1.5px solid #acbacf;
            }

            @media screen and (max-width: 1250px) {
                width: 100%;

                .row {
                    flex-direction: column;
                }
            }

            .group {
                .row {
                    &.list {
                        flex-direction: column;
                        justify-content: unset;

                        padding-left: 1rem;
                        padding-right: 1rem;

                        .item {
                            text-decoration: none;
                            width: 100%;
                        }

                        &-email {
                            @extend .list;

                            padding-left: 0;
                            padding-right: 0;

                            align-items: flex-start;

                            .item {
                                width: 38rem;

                                @media screen and (max-width: 1250px) {
                                    width: fit-content;
                                }
                            }
                        }
                    }

                    input {
                        &[disabled] {
                            opacity: 70%;
                        }
                    }

                    .field-copy {
                        margin-top: 3rem;

                        @media screen and (max-width: 1250px) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1250px) {
            width: 100%;
            min-width: 320px;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
